import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-server-div',
  templateUrl: './image-server-div.component.html',
  styleUrls: ['./image-server-div.component.css']
})
export class ImageServerDivComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer) { }


  @Input() imageURL : any;


  getSafeUrl(){
    return this.sanitizer.bypassSecurityTrustUrl(this.imageURL);
  }

  ngOnInit() {
  }

}
