import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-carousel-article',
  templateUrl: './carousel-article.component.html',
  styleUrls: ['./carousel-article.component.css'],
  providers: [NgbCarouselConfig]  
})
export class CarouselArticleComponent implements OnInit {

  @Input() imagesArray;

  constructor(config: NgbCarouselConfig, private sanitizer: DomSanitizer) { 
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
  }

  getSafeUrl(arrayId : any) {
    return this.sanitizer.bypassSecurityTrustUrl(this.imagesArray[arrayId]);
  }

  ngOnInit() {

  }

}
