import { Component, OnInit } from '@angular/core';
import { trigger, transition, query, animate, style, stagger } from '@angular/animations';
import { Router} from '@angular/router';
import { ConfigService } from '../config.service';

import * as $ from "jquery";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  animations: [
    trigger('header', [
      transition(':enter', [
        query('*', style({ opacity: 0, transform: 'translateY(-15%)' })),
        query('*', stagger('50ms', [
          animate('.1s ease-in', style({ opacity: 1, transform: 'translateY(0)' }))
        ]))
      ]),
      transition(':leave', [
        query('*', style({ opacity: 1, transform: 'translateY(0)' })),
        query('*', stagger('50ms', [
          animate('.1s ease-out', style({ opacity: 0, transform: 'translateY(-15%)' }))
        ]))
      ]),
    ])
  ]
})
export class NavbarComponent implements OnInit {


  constructor(private router: Router, private configService: ConfigService) { }

  connectionStatus: boolean;

  getConnectionStatus(): boolean {
    return this.configService.getConnectionStatus();
  }

  logOut(){
    this.configService.logOut();
    window.location.reload();
  }


  ngOnInit() {


    $(".navbarItem").click(function() {

      var width = $(window).width();
      if (width < 991) {

          setTimeout(function() {

              $(".nav-btn").click();
              console.log("joe");

          }, 500);


      }
  });


    this.connectionStatus = this.getConnectionStatus();

  }

}
