import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConfigService } from '../config.service';
import { Location } from '@angular/common';
import * as $ from 'jquery';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, private configService: ConfigService, private location: Location) { }

  username: any;
  password: any;

  back(){
    this.location.back();
  }


  onSubmit(loginForm) {

    loginForm = JSON.stringify(loginForm);
    let observableLogin;
    let isLogged;



      observableLogin = this.configService.login(loginForm);


      observableLogin.subscribe((data) => {
        if ( data == true){
          this.configService.setConnectionStatus(true);

          $("#successConnection").fadeIn();
          setTimeout(async () => { $("#successConnection").fadeOut(); }, 3000);

        }
        else{
          $("#failedConnection").fadeIn();
          setTimeout(function () { $("#failedConnection").fadeOut(); }, 3000);
        }
      },
      (error) => {
        console.log(error);
      });

  }



  ngOnInit() {

     //scroll when loading and navigating
     this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

  }
}
