
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { PartenairesComponent } from './partenaires/partenaires.component';
import { GalerieComponent } from './galerie/galerie.component';
import { GalerieMariageComponent } from './galerie-mariage/galerie-mariage.component';
import { GalerieEventsComponent } from './galerie-events/galerie-events.component';
import { FooterComponent } from './footer/footer.component';
import { GalerieDivComponent } from './galerie-div/galerie-div.component';
import { CommonModule } from '@angular/common';
import { ArticleComponent } from './article/article.component';
import { DialogArticleComponent } from './dialog-article/dialog-article.component';
import { CarouselHomepageDivComponent } from './carousel-homepage-div/carousel-homepage-div.component';
import { DialogUploadImageComponent } from './dialog-upload-image/dialog-upload-image.component';
import { ImageServerDivComponent } from './image-server-div/image-server-div.component';
import { ImageServerDiv2Component } from './image-server-div2/image-server-div2.component';
import { Article2Component } from './article2/article2.component';
import { ImageServerDiv3Component } from './image-server-div3/image-server-div3.component';
import { CarouselArticleComponent } from './carousel-article/carousel-article.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PartenaireDivComponent } from './partenaire-div/partenaire-div.component';
import { DialogPartenaireComponent } from './dialog-partenaire/dialog-partenaire.component';
import { BadgeComponent } from './badge/badge.component';
import { DialogBadgeComponent } from './dialog-badge/dialog-badge.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';

import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule} from '@angular/material';
import { HttpClientModule } from '@angular/common/http'
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FileUploadModule } from 'ng2-file-upload';
import { PreloadAllModules } from '@angular/router';











const appRoutes: Routes = [
  { path: '', redirectTo: 'accueil', pathMatch: 'full' },
  { path: 'accueil', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'partenaires', component: PartenairesComponent },
  { path: 'galerie', component: GalerieComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'MentionsLegales', component: MentionsLegalesComponent },
  { path: 'login', component: LoginComponent },
  { path: 'galerie-mariage', component: GalerieMariageComponent },
  { path: 'galerie-evenements', component: GalerieEventsComponent },
  { path: 'galerie-mariage/articles/:id', component: ArticleComponent },
  { path: 'galerie-evenements/articles/:id', component: Article2Component },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    AboutComponent,
    ServicesComponent,
    PartenairesComponent,
    GalerieComponent,
    FooterComponent,
    ContactComponent,
    LoginComponent,
    GalerieMariageComponent,
    GalerieEventsComponent,
    GalerieDivComponent,
    ArticleComponent,
    DialogArticleComponent,
    CarouselHomepageDivComponent,
    DialogUploadImageComponent,
    ImageServerDivComponent,
    ImageServerDiv2Component,
    Article2Component,
    ImageServerDiv3Component,
    CarouselArticleComponent,
    NotFoundComponent,
    PartenaireDivComponent,
    DialogPartenaireComponent,
    BadgeComponent,
    DialogBadgeComponent,
    MentionsLegalesComponent
  ],
  entryComponents: [
    GalerieDivComponent,
    DialogArticleComponent,
    CarouselHomepageDivComponent,
    DialogUploadImageComponent,
    ImageServerDivComponent,
    ImageServerDiv2Component,
    ImageServerDiv3Component,
    CarouselArticleComponent,
    PartenaireDivComponent,
    DialogPartenaireComponent,
    BadgeComponent,
    DialogBadgeComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload'
    }),
    RouterModule.forRoot(appRoutes,
      { preloadingStrategy: PreloadAllModules }),
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSliderModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    FileUploadModule
  ],

  providers: [],
  bootstrap: [AppComponent],
  exports:[RouterModule, DialogPartenaireComponent, BadgeComponent, DialogBadgeComponent]

})
export class AppModule { }
