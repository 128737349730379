import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import { ConfigService } from '../config.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Inject } from '@angular/core';


@Component({
  selector: 'app-dialog-article',
  templateUrl: './dialog-article.component.html',
  styleUrls: ['./dialog-article.component.css']
})
export class DialogArticleComponent implements OnInit {

  constructor(private configService: ConfigService, private formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogArticleComponent>) { }

 
  mainFile : File;
  multipleFiles: Array<File>;
  form: FormGroup;

  error: string;
  uploadResponse = { status: '', message: '', filePath: '' };


  back() {
    this.dialogRef.close()
  }



  onSubmit(form) {

    form.dialogId = this.data.id;
    
    async function addArticle(that: any, form : any) {

      let serverResponse = await that.configService.addArticle(form, that.mainFile, that.multipleFiles);

    }
    
    window.location.reload();
    

    addArticle(this, form);
  }

  onFileChange(event) {

    let selectedFile = <File>event.target.files[0];

    var file_ext = selectedFile.name.substr(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length);

    if (file_ext == "jpg") {
      
      this.mainFile = selectedFile;

    }
    else {
      
    }

  }


  onMultipleFilesChange(event){

    let allFilesAreJPG = false;
    let elementValidated = 0;
    let multipleFiles = <Array<File>>event.target.files;

    if (multipleFiles.length < 13){

      if (allFilesAreJPG == false ){

          for(let i = 0; i < multipleFiles.length ; i++){
            
          var file_ext = multipleFiles[i].name.substr(multipleFiles[i].name.lastIndexOf('.') + 1, multipleFiles[i].name.length);

          if (file_ext == "jpg") {
            // this.isDisabled = false;
            elementValidated++;
            if (elementValidated == multipleFiles.length){
              allFilesAreJPG = true;
            }
          }

          else {
            
          }
  
        }
      }
      if (allFilesAreJPG == true){
        this.multipleFiles = multipleFiles;
      }  

    }
    else {
      
    }


  }

  
  ngOnInit() {

    

  }

}
