import { Component, OnInit, Inject } from '@angular/core';
import { ConfigService } from '../config.service';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-upload-image',
  templateUrl: './dialog-upload-image.component.html',
  styleUrls: ['./dialog-upload-image.component.css']
})
export class DialogUploadImageComponent implements OnInit {

  constructor(private configService: ConfigService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogUploadImageComponent>) { }

  selectedFile : File = null;

  isDisabled = true ;

  onFileSelected(event){

    this.selectedFile = <File>event.target.files[0];

    var file_ext = this.selectedFile.name.substr(this.selectedFile.name.lastIndexOf('.') + 1, this.selectedFile.name.length);


    if(file_ext == "jpg"){
      document.getElementById('errorMessage').setAttribute('style', 'display:none !important');
      this.isDisabled =  false;
    }
    else{
      document.getElementById('errorMessage').setAttribute('style', 'display:block !important');
    }
  }



  onUpload(){


    async function uploadSingleImage(that :any){


      let serverResponse = await that.configService.changeSingleImage(that.selectedFile, that.data.id);

    }

    window.location.reload();
    uploadSingleImage(this);
  }


  


  back() {
    this.dialogRef.close()
  }
  
  ngOnInit() {
  }

}
