import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '../config.service';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.css']
})
export class BadgeComponent implements OnInit {

  @Input() id: string;
  @Input() imageURL: string;

  connectionStatus: boolean;

  constructor(private sanitizer: DomSanitizer, private configService: ConfigService) { }

  deleteBadge() {

    if (confirm("Supprimer Le Badge?")) {

      async function deleteBadge(that: any) {


        let serverResponse = await that.configService.deleteBadge(that.id);
      }

      window.location.reload();
      deleteBadge(this);

    }
    else {

    return;
    }
  }


  getConnectionStatus(): boolean {
    return this.configService.getConnectionStatus();
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustUrl(this.imageURL);
  }


  ngOnInit() {

    this.connectionStatus = this.getConnectionStatus();
  }

}
