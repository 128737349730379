import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-server-div2',
  templateUrl: './image-server-div2.component.html',
  styleUrls: ['./image-server-div2.component.css']
})
export class ImageServerDiv2Component implements OnInit {

  constructor(private sanitizer: DomSanitizer) { }


  @Input() imageURL: any;


  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustUrl(this.imageURL);
  }

  ngOnInit() {
  }

}
