import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel-homepage-div',
  templateUrl: './carousel-homepage-div.component.html',
  styleUrls: ['./carousel-homepage-div.component.css'],
  providers: [NgbCarouselConfig]  
})
export class CarouselHomepageDivComponent implements OnInit {

  @Input() imagesArray;

  constructor(config: NgbCarouselConfig) {
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
   }

  ngOnInit() {

  }

}
