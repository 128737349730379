import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { trigger, style, animate, transition, state } from '@angular/animations';
import { ConfigService } from '../config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-galerie-div',
  templateUrl: './galerie-div.component.html',
  styleUrls: ['./galerie-div.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeIn', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ])
  ]
})
export class GalerieDivComponent implements OnInit {

  @Input() id: string;
  @Input() names: string;
  @Input() date: string;
  @Input() mainImageSRC: string;

  connectionStatus: boolean;

  getConnectionStatus(): boolean {
    return this.configService.getConnectionStatus();
  }

  constructor(private configService: ConfigService, private sanitizer: DomSanitizer, private router: Router) { }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustUrl(this.mainImageSRC);
  }

  deleteArticle() {

    if (confirm("Supprimer l'article?")) {

      async function deleteArticle(that: any) {

        let collectionToUse;
        let result = that.router.url;
        if (result == "/galerie-evenements"){
          collectionToUse = "otherEventsArray";
        }
        if (result == "/galerie-mariage") {
          collectionToUse = "galerieMariageArray";
        }

        let serverResponse = await that.configService.deleteArticle(that.id, collectionToUse);
      }

      window.location.reload();
      deleteArticle(this);

    }
    else {

    return;
    }
  }

  ngOnInit() {



    this.connectionStatus = this.getConnectionStatus();
  }

}
