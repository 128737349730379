import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private http: HttpClient, private router: Router) { }

  dialogRefGalerieMariage: any;
  dialogRefGalerieOtherEvents: any;
  whereTheDialogWasOpenedFrom : any;

  private isLogged = JSON.parse(localStorage.getItem('loggedIn' || 'false'));


  jsonGalerieMariageArray : any;
  jsonOtherEventsArray: any;
  jsonPartenairesArray: any;
  jsonBadgesArray : any;

  private serverURL = 'http://146.88.236.28:3000';








  // Get server URL
  getURL() {

    return this.serverURL;
  }

  // Set server URL

  setURL(value) {

    this.serverURL = value;
  }

  // Set connection status in local storage to allow user not to lost connection by refreshing the page

  setConnectionStatus(value: boolean) {

    this.isLogged = value;
    localStorage.setItem('loggedIn', 'true');
  }

  logOut(){
    this.isLogged = false;
    localStorage.setItem('loggedIn', 'false');
  }

  // get status connection from local storage
  getConnectionStatus(): boolean {

    return JSON.parse(localStorage.getItem('loggedIn' || this.isLogged.tostring()));
  }

  // Send contact form to server and mail it

  async sendContactForm(form : any) {

    return new Promise(resolve => {

      let privateURL = this.getURL();
      let url = privateURL+'/sendContactForm';

      console.log(url);
      var formJSON = JSON.parse(form);

      this.http.post(url, formJSON).subscribe(
        (value) => {

          resolve(value);
        });
    });
  }

  // Login for modifying photos and articles

  login(form: any): Observable<any> {

    let privateURL = this.getURL();
    let url = privateURL +'/login';

    var formJSON = JSON.parse(form);
    return this.http.post(url, formJSON);

  }

  // Convert URI to Blob type

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  // Return any article informations with its id in mariage galerie

  async getObjectByIdInMariageArray(idToFetch: number) {

    return new Promise(resolve => {
    let article: any;


    const URL = `${this.getURL()}/getObjectByIdMariageArray/`+ idToFetch;


      this.http.get(URL).subscribe(
        (value) => {

        resolve(value);
      },(error) => {

        this.router.navigate(['/404']);
      });
    });
  };

  // Return any article informations with its id in other events galerie
  async getObjectByIdInOtherEventsArray(idToFetch: number) {

    return new Promise(resolve => {
      let article: any;


      const URL = `${this.getURL()}/getObjectByIdOtherEventsArray/` + idToFetch;


      this.http.get(URL).subscribe(
        (value) => {

        resolve(value);
      },(error) =>{

        this.router.navigate(['/404']);
      });
    });
  };


  // Retrieve all articles in mariage galerie

  async fetchJsonGalerieMariageArray() {
    return new Promise(resolve => {

      const URL = `${this.getURL()}/getJsonGalerieMariageArray`;
      const observable = this.http.get(URL);


      observable.subscribe((value) => {


        // refresh GalerieMariageArray
        this.jsonGalerieMariageArray = value;
        resolve(value);
      });
    });

  }

  // Retrieve all articles in other events galerie

  async fetchJsonGalerieOtherEventsArray() {
    return new Promise(resolve => {

      const URL = `${this.getURL()}/getJsonOtherEventsArray`;
      const observable = this.http.get(URL);


      observable.subscribe((value) => {

        // refresh GalerieMariageArray
        this.jsonOtherEventsArray = value;
        resolve(value);
      });
    });

  }

  // Retrieve all partenaires

  async fetchJsonPartenairesArray() {
    return new Promise(resolve => {

      const URL = `${this.getURL()}/getPartenairesArray`;
      const observable = this.http.get(URL);


      observable.subscribe((value) => {

        // refresh partenairesArray
        this.jsonPartenairesArray = value;
        resolve(value);
      });
    });

  }

   // Retrieve all badges

    async  fetchJsonBadgesArray() {
      return new Promise(resolve => {

        const URL = `${this.getURL()}/getBadgesArray`;
        const observable = this.http.get(URL);


        observable.subscribe((value) => {

          // refresh badgesArray
          this.jsonBadgesArray = value;
          resolve(value);
        });
      });

    }

  // Upload a single photo at the time
  async changeSingleImage(selectedFile: File, id : any) {

    if (this.isLogged){
      return new Promise(resolve => {

        const data = new FormData();

        data.set('file', selectedFile) ;
        data.append('dialogId', id);

        const URL = `${this.getURL()}/changeImage`;
        const observable = this.http.post(URL, data);

        observable.subscribe((value) => {

          resolve(value);
        });

      });
    }
  }

    // Add a new partenaire
    async addPartenaire(selectedFile: File, id : any) {

      if (this.isLogged){
        return new Promise(resolve => {

          const data = new FormData();

          data.set('file', selectedFile) ;

          const URL = `${this.getURL()}/addPartenaire`;
          const observable = this.http.post(URL, data);

          observable.subscribe((value) => {

            resolve(value);
          });

        });
      }
    }

      // Add a new badge
      async addBadge(selectedFile: File, id : any) {

        if (this.isLogged){
          return new Promise(resolve => {

            const data = new FormData();

            data.set('file', selectedFile) ;

            const URL = `${this.getURL()}/addBadge`;
            const observable = this.http.post(URL, data);

            observable.subscribe((value) => {

              resolve(value);
            });

          });
        }
      }

  // Get a photo
  async getSingleImage(id: any) {

    return new Promise(resolve => {

      const URL = `${this.getURL()}/getSingleImage/`+ id;
      const observable = this.http.get(URL);

      observable.subscribe((value) => {

        resolve(value);
      });

    });

  }

  // Upload a single photo at the time
  async addArticle(JSON: any, mainFile: File, arrayFiles: Array<File>) {

    if (this.isLogged){
      return new Promise(resolve => {

        const data = new FormData();

        if (mainFile == null || arrayFiles == null){
          return;
        }
        data.append("multipleFiles[]", mainFile);

        for (let i = 0; i < arrayFiles.length; i++) {
          data.append("multipleFiles[]", arrayFiles[i]);
        }


        data.append('dialogId', JSON.dialogId);
        data.append('date', JSON.date);
        data.append('names', JSON.names);
        data.append('description', JSON.description);



        const URL = `${this.getURL()}/addArticle`;
        const observable = this.http.post(URL, data);

        observable.subscribe((value) => {

          resolve(value);
        });

      });
    }
  }

  // Get a photo
  async deleteArticle(idArticle: any, collectionToUse : string) {

    if (this.isLogged){
      return new Promise(resolve => {

        let data : any = {
          idArticle: idArticle,
          collectionToUse: collectionToUse
        }

        data = JSON.stringify(data);
        data = JSON.parse(data);

        const URL = `${this.getURL()}/deleteArticle/`;
        const observable = this.http.post(URL, data );

        observable.subscribe((value) => {

          resolve(value);
        });

      });
    }
  }

   // Delete Partenaire
   async deletePartenaire(idPartenaire: any) {

    if (this.isLogged){
      return new Promise(resolve => {

        let data : any = {
          idPartenaire: idPartenaire
        }

        data = JSON.stringify(data);
        data = JSON.parse(data);

        const URL = `${this.getURL()}/deletePartenaire/`;
        const observable = this.http.post(URL, data );

        observable.subscribe((value) => {

          resolve(value);
        });

      });
    }
  }

     // Delete Partenaire
     async deleteBadge(idBadge: any) {

      if (this.isLogged){
        return new Promise(resolve => {

          let data : any = {
            idBadge: idBadge
          }

          data = JSON.stringify(data);
          data = JSON.parse(data);

          const URL = `${this.getURL()}/deleteBadge/`;
          const observable = this.http.post(URL, data );

          observable.subscribe((value) => {

            resolve(value);
          });

        });
      }
    }


  // Convert base64 image to blob type

  convertBase64ToBlobLocal( imageToConvert : any){

    const date = new Date().valueOf();
    let text = '';
    const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
    }
    // Replace extension according to your media type
    const imageName = date + '.' + text + '.jpeg';

    // call method that creates a blob from dataUri
    const imageBlob = this.dataURItoBlob(imageToConvert);
    const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });

    let generatedImage = window.URL.createObjectURL(imageFile);

    return generatedImage;
  }

  ngOnInit() {



  }
}
