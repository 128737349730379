import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConfigService } from '../config.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { pulse } from 'ng-animate';
import * as $ from 'jquery';
import { DialogUploadImageComponent } from '../dialog-upload-image/dialog-upload-image.component';
import { MatDialog } from '@angular/material';
import { ImageServerDiv2Component } from '../image-server-div2/image-server-div2.component';
import {
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver
} from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {


  constructor(private router: Router, private configService: ConfigService, private resolver: ComponentFactoryResolver, private dialog: MatDialog) { }

  @ViewChild('imageFromServerContainer3', { static: true, read: ViewContainerRef }) entry2: ViewContainerRef;

  @ViewChild('imageFromServerContainer4', { static: true, read: ViewContainerRef }) entry3: ViewContainerRef;

  connectionStatus : any;

  getConnectionStatus(): boolean {
    return this.configService.getConnectionStatus();
  }

  openDialog(idDialogString: string) {

    let dialogRef = this.dialog.open(DialogUploadImageComponent, {
      height: 'auto',
      width: 'auto',
      data: { id: idDialogString }
    });
  }

  ngOnInit() {

    this.connectionStatus = this.getConnectionStatus();

    // get photos from server
    async function getSingleImage(that: any, id: any, entryContainer: any) {

      let serverResponse = await that.configService.getSingleImage(id);



      let generatedImage = that.configService.convertBase64ToBlobLocal(serverResponse);


      const factory = that.resolver.resolveComponentFactory(ImageServerDiv2Component);
      const componentRef = entryContainer.createComponent(factory);

      componentRef.instance.imageURL = generatedImage;


    }

    getSingleImage(this, 3, this.entry2);
    getSingleImage(this, 4, this.entry3);




    // jquerry animation init

    $(document).ready(function () {

      // Function which adds the 'animated' class to any '.animatable' in view
      var doAnimations = function () {

        // Calc current offset and get all animatables
        var offset = $(window).scrollTop() + $(window).height(),
          $animatables = $('.animatable');

        // Unbind scroll handler if we have no animatables
        if ($animatables.length == 0) {
          $(window).off('scroll', doAnimations);
        }

        // Check all animatables and animate them if necessary
        $animatables.each(function (i) {
          var $animatable = $(this);
          if (($animatable.offset().top + $animatable.height() - 20) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
          }
        });

      };

      // Hook doAnimations on scroll, and trigger a scroll
      $(window).on('scroll', doAnimations);
      $(window).trigger('scroll');

    });


    //scroll en haut quand on arrive
    window.onbeforeunload = function () {
      $("#body").fadeOut();
      window.scrollTo(0, 0);
    }

     //scroll when loading and navigating
     this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

  }
}
