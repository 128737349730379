import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../config.service';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-badge',
  templateUrl: './dialog-badge.component.html',
  styleUrls: ['./dialog-badge.component.css']
})
export class DialogBadgeComponent implements OnInit {

  constructor(private configService: ConfigService, public dialogRef: MatDialogRef<DialogBadgeComponent>) { }

  selectedFile : File = null;

  isDisabled = true ;

  onFileSelected(event){

    this.selectedFile = <File>event.target.files[0];

    this.isDisabled = false;
  }


  onUpload(){

    async function addBadge(that :any){

      let serverResponse = await that.configService.addBadge(that.selectedFile);
    }

    window.location.reload();
    addBadge(this);
  }

  back() {
    this.dialogRef.close()
  }



  ngOnInit() {
  }

}
