import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ConfigService } from '../config.service';
import Axios from 'axios';
import * as $ from 'jquery';
import {
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver
} from '@angular/core';
import { CarouselHomepageDivComponent } from '../carousel-homepage-div/carousel-homepage-div.component';
import { DialogUploadImageComponent } from '../dialog-upload-image/dialog-upload-image.component';
import { MatDialog } from '@angular/material';
import { ImageServerDivComponent } from '../image-server-div/image-server-div.component';
import { Directive } from '@angular/core';
import { BadgeComponent } from '../badge/badge.component';
import { DialogBadgeComponent } from '../dialog-badge/dialog-badge.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

@Directive({
  selector: 'div[loaded]'
})

export class HomeComponent implements OnInit {


  constructor(private router: Router, private configService: ConfigService, private resolver: ComponentFactoryResolver, private dialog: MatDialog) {
   }

  @ViewChild('carouselDivContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;

  @ViewChild('imageFromServerContainer', { static: true, read: ViewContainerRef }) entry2: ViewContainerRef;

  @ViewChild('imageFromServerContainer2', { static: true, read: ViewContainerRef }) entry3: ViewContainerRef;

  @ViewChild('badgesContainer', { static: true, read: ViewContainerRef }) entry4: ViewContainerRef;

  connectionStatus: boolean;


  getConnectionStatus(): boolean {
    return this.configService.getConnectionStatus();
  }

  redirectToInstagram(){
    window.location.href = 'https://www.instagram.com/Floramaria.fr/';
  }

  openDialog(idDialogString : string) {

    let dialogRef = this.dialog.open(DialogUploadImageComponent, {
      height: 'auto',
      width: 'auto',
      data: { id: idDialogString }
    });
  }

  openDialog2() {
    let dialogRef = this.dialog.open(DialogBadgeComponent, {
      height: 'auto',
      width: 'auto'
    });
  }

  ngOnInit() {

    this.connectionStatus = this.getConnectionStatus();




    // get photos from server
    async function getSingleImage(that: any, id : any, entryContainer : any) {

      let serverResponse = await that.configService.getSingleImage(id);

      if(serverResponse == "error"){
        const factory = that.resolver.resolveComponentFactory(ImageServerDivComponent);
        const componentRef = entryContainer.createComponent(factory);

        componentRef.instance.imageURL = null;
      }

      else{

        let generatedImage = that.configService.convertBase64ToBlobLocal(serverResponse);

        const factory = that.resolver.resolveComponentFactory(ImageServerDivComponent);
        const componentRef = entryContainer.createComponent(factory);

        componentRef.instance.imageURL = generatedImage;

      }

    }

    getSingleImage(this, 1, this.entry2);
    getSingleImage(this, 2, this.entry3);



    // create badges components

    async function CreateBadgeComponent(that) {

      var arrayRetrieved = await that.configService.fetchJsonBadgesArray();

      var newArray = [];

      arrayRetrieved.forEach(element => {
        element.imageURL = that.configService.convertBase64ToBlobLocal(element.imageURL);
        newArray.push(element);
      });

      arrayRetrieved = newArray;

      let eventsArray = arrayRetrieved;


      let eventsArraylength = 0;

      for (let items in eventsArray) {
        eventsArraylength++;
      }

      that.arrayLenghtRetrievedFromServer = eventsArraylength;

      for (let i = 0; i < eventsArraylength; i++) {

        const factory = that.resolver.resolveComponentFactory(BadgeComponent);
        const componentRef = that.entry4.createComponent(factory);


        componentRef.instance.id = eventsArray[i].id;
        componentRef.instance.imageURL = eventsArray[i].imageURL;
      }

    }

    CreateBadgeComponent(this);







    // retrieve insta photos

    async function instagramPhotos() {
      // It will contain our photos' links
      const res = [];

      try {
        const userInfoSource = await Axios.get('https://www.instagram.com/Floramaria.fr/');

        // userInfoSource.data contains the HTML from Axios
        const jsonObject = userInfoSource.data.match(/<script type="text\/javascript">window\._sharedData = (.*)<\/script>/)[1].slice(0, -1);

        const userInfo = JSON.parse(jsonObject);

        // Retrieve only the first 7 results
        const mediaArray = userInfo.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(0, 7);
        for (let media of mediaArray) {
          const node = media.node;

          // Process only if is an image
          if ((node.__typename && node.__typename !== 'GraphImage')) {
            continue;
          }

          // Push the thumbnail src in the array
          res.push(node.thumbnail_src);
        }
        } catch (e) {
          console.error('Unable to retrieve photos. Reason: ' + e.toString());
        }

        return res;
      }

      let that = this;
      instagramPhotos().then(function (data) {

      let array = [];

      for (let i = 0; i < 7; i++) {
        array.push(data[i]);
      }

      const factory = that.resolver.resolveComponentFactory(CarouselHomepageDivComponent);
      const componentRef = that.entry.createComponent(factory);

      componentRef.instance.imagesArray = array;


      });










    //scroll when loading and navigating
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }





    // carousel

    function moveToSelected(element) {


      if (element == "next") {
        var selected = $(".selected").next();
      } else if (element == "prev") {
        var selected = $(".selected").prev();
      } else {
        selected = element;
      }

      var next = $(selected).next();
      var prev = $(selected).prev();
      var prevSecond = $(prev).prev();
      var nextSecond = $(next).next();

      $(selected).removeClass().addClass("selected");

      $(prev).removeClass().addClass("prev");
      $(next).removeClass().addClass("next");

      $(nextSecond).removeClass().addClass("nextRightSecond");
      $(prevSecond).removeClass().addClass("prevLeftSecond");

      $(nextSecond).nextAll().removeClass().addClass('hideRight');
      $(prevSecond).prevAll().removeClass().addClass('hideLeft');

    }

    $(document).keydown(function (e) {

      switch (e.which) {
        case 37: // left
          moveToSelected('prev');
          break;

        case 39: // right
          moveToSelected('next');
          break;

        default: return;
      }
      e.preventDefault();
    });

    $('#carousel div').click(function () {
      moveToSelected($(this));
    });

    $('#prev').click(function () {
      moveToSelected('prev');
    });

    $('#next').click(function () {
      moveToSelected('next');
    });

  }

}
