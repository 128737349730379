import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Input } from '@angular/core';
import { ConfigService } from '../config.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CarouselArticleComponent } from '../carousel-article/carousel-article.component';

@Component({
  selector: 'app-article2',
  templateUrl: './article2.component.html',
  styleUrls: ['./article2.component.css']
})
export class Article2Component implements OnInit {
  
  constructor(private route: ActivatedRoute, private configService: ConfigService, config: NgbCarouselConfig, private router: Router, private resolver: ComponentFactoryResolver, private sanitizer: DomSanitizer) {}

  images = ["assets/img/image1.jpg", "assets/img/image2.jpg", "assets/img/image14.jpg", "assets/img/image12.jpg", "assets/img/image4.jpg", "assets/img/image6.jpg", "assets/img/image13.jpg", "assets/img/image8.jpg", "assets/img/image6.jpg", "assets/img/image10.jpg", "assets/img/image11.jpg", "assets/img/image12.jpg", "assets/img/image13.jpg"];

  @ViewChild('carouselDivContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
  
  @Input() id: number;
  @Input() names: string;
  @Input() date: string;
  @Input() imagesArray: any;
  @Input() description: string;
  @Input() mainImageSRC: string;

  back() {
    this.router.navigate(['/galerie-evenements']);
  }

  getSafeUrl() {
    return this.sanitizer.bypassSecurityTrustUrl(this.mainImageSRC);
  }

  ngOnInit() {

    async function init(that: any) {

      that.id = that.route.snapshot.params['id'];
      let object = await that.configService.getObjectByIdInOtherEventsArray(that.id);

      
      that.names = object.names;
      that.date = object.date;
      that.mainImageSRC = that.configService.convertBase64ToBlobLocal(object.mainImageSRC);
      let localArray = [];
      object.imagesArray.forEach(element => {
        localArray.push(that.configService.convertBase64ToBlobLocal(element));
      });
      that.imagesArray = localArray;
      that.description = object.description;
   

      document.getElementById('description').innerHTML = that.description;

      const factory = that.resolver.resolveComponentFactory(CarouselArticleComponent);
      const componentRef = that.entry.createComponent(factory);

      componentRef.instance.imagesArray = that.imagesArray;
    }

    init(this);

  }

}
