import { Router, NavigationEnd } from '@angular/router';
import { PartenaireDivComponent } from '../partenaire-div/partenaire-div.component';
import {
  Component, OnInit, ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { ConfigService } from '../config.service';
import { MatDialog } from '@angular/material';
import { DialogPartenaireComponent } from '../dialog-partenaire/dialog-partenaire.component';
import { trigger, style, animate, transition, state, useAnimation } from '@angular/animations';
import { pulse } from 'ng-animate';

@Component({
  selector: 'app-partenaires',
  templateUrl: './partenaires.component.html',
  styleUrls: ['./partenaires.component.css'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeIn', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 })))
    ]),
    [
      trigger('pulse', [transition('* => *', useAnimation(pulse, { params: { timings: 200, scale: 1.038 } }))]),
    ]
  ]
})
export class PartenairesComponent implements OnInit {

  constructor(private resolver: ComponentFactoryResolver, private router: Router, private configService: ConfigService, private dialog: MatDialog, private http: HttpClient) { }

  @ViewChild('partenaireDivContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;


  connectionStatus: any;

  getConnectionStatus(): boolean {
    return this.configService.getConnectionStatus();
  }

  openDialog() {
    let dialogRef = this.dialog.open(DialogPartenaireComponent, {
      height: 'auto',
      width: 'auto'
    });
  }



  ngOnInit() {

    this.connectionStatus = this.getConnectionStatus();

    async function CreatePartenairesDivComponent(that) {

      var arrayRetrieved = await that.configService.fetchJsonPartenairesArray();


      var newArray = [];

      arrayRetrieved.forEach(element => {
        element.imageURL = that.configService.convertBase64ToBlobLocal(element.imageURL);
        newArray.push(element);
      });

      arrayRetrieved = newArray;

      let partenairesArray = arrayRetrieved;
      let partenairesArraylength = 0;


      for (let items in partenairesArray) {
        partenairesArraylength++;
      }


      for (let i = 0; i < partenairesArraylength; i++) {

        const factory = that.resolver.resolveComponentFactory(PartenaireDivComponent);
        const componentRef = that.entry.createComponent(factory);


        componentRef.instance.id = partenairesArray[i].id;
        componentRef.instance.imageURL = partenairesArray[i].imageURL;

      }

    }

    CreatePartenairesDivComponent(this);








     //scroll when loading and navigating
     this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }

  }
}
